$p: ui;
.main {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));

  @apply
    bg-gray-095
    flex
    flex-col
    gap-12
    items-center
    justify-center
    p-12
    w-full
  ;

  @screen sm {
    @apply gap-4 p-4;
  }
}

.card {
  max-width: 846px;
  @apply
  gap-12 w-full;
}

.form {
  gap: 48px 32px;
  grid-template-columns: 1fr 1fr;
  @apply
    flex-col
    grid
    items-center
    w-full
  ;

  @screen md {
    grid-template-columns: 1fr;
  }

  @screen sm {
    gap: 32px;
  }
}

.one-row {
  grid-column: 1 / 3;
  @apply flex flex-col gap-4;

  @screen md {
    grid-column: 1;
  }
}

.link {
  justify-self: end;
  @apply
    font-semibold
    text-base
    text-lavender-100
  ;

  @screen md {
    justify-self: start;
  }
}
