$p: ui;
.footer {
  height: var(--footer-height);

  @apply
    bg-gray-100
    border-gray-090
    border-solid
    border-t
    content-center
    flex
    flex-wrap
    font-semibold
    gap-x-5
    gap-y-0
    items-center
    justify-center
    shadow
    text-center
    text-lavender-100
    text-sm
    w-full
  ;

}
