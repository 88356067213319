$p: ui;
.header {
  transition: all 500ms;
  @apply
    gap-8
    justify-center
    z-40
  ;

  @screen sm {
    @apply px-4;
  }
}

.content {
  max-width: var(--content-width);
  @apply
    flex
    flex-row
    gap-4
    items-center
    justify-between
    w-full
  ;
}

.menu {
  @apply
    flex
    flex-row
    flex-shrink-0
    gap-4
    items-center
  ;

  .button {
    @apply
      cursor-pointer
      h-16
      p-0
      w-16
    ;

    svg {
      @apply h-6 m-0 w-6;
    }
  }

  .link {
    margin: -19px -24px;
    padding: 19px 24px;
    @apply block;
  }
}
